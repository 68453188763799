import { DeploymentConfiguration } from 'api/resources/models/AutoGenerated';
import { SelectOption } from 'components/Select';

export enum Ids {
  Allianz = 1,
  Covea = 61,
  DLG2 = 67,
  Caps_IMS = 178,
  LV = 74,
  RSA = 69,
  Kindertons = 156,
  FixCloud = 193,
  CircleLeasing = -2,
  ITAS_V2 = -3,
  Caps_Enterprise_ITB = 292,
  Caps_Enterprise = 150,
  Innovation = 36
}

export interface CustomRepairerConnectionForm {
  workProvider: SelectOption<number | undefined> | null;
  ticketId: string | null;
  serviceStartDate: string | null;
  comment: string | null;
  repairerId: number;
  externalCode: string | null;
  deploymentType: SelectOption | null;
  schemeCodeOverride: string | null;
  deployToSiteCode: string | null;
  deployToEmail: string | null;
  coveaCode: string | null;
  sysType: SelectOption | null;
  externalRepairerName: string | null;
  kindertonsCode: string | null;
  fixSiteCode: string | null;
  customSettings: string | null;
  overwriteExisting: boolean | null;
  fixType: SelectOption | null;
  deploymentMethods: DeploymentConfiguration[] | null;
  manufacturer: string | null;
  isExistingConnection: boolean;
  isRequiresSecondReference: boolean;
}
